import React, { Component } from 'react'
import { Box, Grid, ResponsiveContext } from 'grommet'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import Img from 'gatsby-image'
import shortid from 'shortid'

const Rows = ({ intl, data }) => {
  const { messages } = intl
  const number = messages['rows.number']
  const columns = Number(messages['rows.columns'])
  const title = intl.formatMessage({
    id: `rows.title`,
  })
  const hasTitle = title !== 'null'
  const description = intl.formatMessage({
    id: `rows.description`,
  })
  const hasDescription = description !== 'null'

  return (
    <Box
      a11yTitle="rows"
      direction="column"
      justify="center"
      align="center"
      pad="xlarge"
      background="dark-1"
      gap="medium"
    >
      {hasTitle && <h2>{title}</h2>}
      {hasDescription && <p>{description}</p>}
      <ResponsiveContext.Consumer>
        {size => {
          const count =
            size === 'large' || size === 'xlarge'
              ? columns
              : size === 'medium'
              ? columns - 1
              : 1
          return (
            <Grid gap="xlarge" columns={{ count: count, size: 'auto' }} fill>
              {Array.from(Array(Number(number)).keys()).map((row, index) => {
                const rowTitle = intl.formatMessage({
                  id: `rows.row${index}.title`,
                })
                const hasRowTitle = rowTitle !== 'null'
                const rowDescription = intl.formatMessage({
                  id: `rows.row${index}.description.description`,
                })
                const hasRowDescription = rowDescription !== 'null'
                const isRowRichText =
                  intl.formatMessage({
                    id: `rows.row${index}.description.richText`,
                  }) !== 'null'

                const rowLink = intl.formatMessage({
                  id: `rows.row${index}.link`,
                })
                const hasRowLink = rowLink !== 'null'

                const iconPath = intl.formatMessage({
                  id: `rows.row${index}.icon`,
                })
                let hasIcon = false
                let imagePath = 'imagePath'
                if (iconPath !== 'null') {
                  imagePath = data[`${iconPath}`].childImageSharp.fluid
                  hasIcon = true
                }

                return (
                  <Box
                    pad="large"
                    align="center"
                    background={{ color: 'light-1', opacity: 'strong' }}
                    round="xsmall"
                    gap="xsmall"
                    key={shortid.generate()}
                  >
                    {hasIcon && (
                      <Box width="xlarge" align="center">
                        <Img
                          style={{ width: '42px', height: '42px' }}
                          fluid={imagePath}
                        />
                      </Box>
                    )}
                    {hasRowTitle && <h3>{rowTitle}</h3>}
                    {hasRowDescription && !isRowRichText ? (
                      <p>{rowDescription}</p>
                    ) : (
                      hasRowDescription &&
                      isRowRichText && (
                        <p dangerouslySetInnerHTML={{ __html: rowDescription }} />
                      )
                    )}
                    {hasRowLink && (
                      <a href={rowLink} target="_blank">
                        {rowLink}
                      </a>
                    )}
                  </Box>
                )
              })}
            </Grid>
          )
        }}
      </ResponsiveContext.Consumer>
    </Box>
  )
}

export default injectIntl(Rows)

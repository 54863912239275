import {
  StyledColumnButtonContainer,
  StyledColumnContainer,
  StyledColumnsContainer,
  StyledContainer,
  StyledH3,
  StyledSpan,
  StyledUnderlinedH2
} from './styles'
import { injectIntl, intlShape } from 'react-intl'

import { Box } from 'grommet'
import Button from '../reusables/button'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledContentContainer = styled(Box)``

const StyledImageContainer = styled(Box)`
  width: 190px;
  height: auto;
`

const StyledButtonContainer = styled(Box)`
  display: grid;
  align-items: start;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 20px;
  margin-top: 48px;
  width: 100%;
  ${media.greaterThan('small')`
    width: auto;
  `}
`

const Centered = ({ index, data, shade, intl }) => {
  const section = `centered.centered${index}`
  const title = intl.formatMessage({
    id: `${section}.title`,
  })
  const hasTitle = title !== 'null'
  const description = intl.formatMessage({
    id: `${section}.description.description`,
  })
  const hasDescription = description !== 'null'
  const isRichText =
    intl.formatMessage({
      id: `${section}.description.richText`,
    }) !== 'null'

  const buttons = intl.formatMessage({
    id: `${section}.buttons.number`,
  })

  const images = intl.formatMessage({
    id: `${section}.images.number`,
  })

  const backgroundImage = intl.formatMessage({
    id: `${section}.backgroundImage`,
  })

  return (
    <StyledContainer
      componentName="Centered"
      shade={shade}
      data={data}
      backgroundImage={backgroundImage}
    >
      <StyledContentContainer align="center" justify="center">
        {hasTitle && <StyledUnderlinedH2>{title}</StyledUnderlinedH2>}
        {hasDescription && !isRichText ? (
          <StyledSpan>{description}</StyledSpan>
        ) : (
          hasDescription &&
          isRichText && (
            <StyledSpan
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )
        )}
        {images > 0 && (
          <Box
            direction="row-responsive"
            gap="medium"
            pad={{ vertical: 'medium' }}
            justify="between"
          >
            {Array.from(Array(Number(images)).keys()).map(i => {
              const columnImage = `${section}.images.image${i}`

              const imageLink = intl.formatMessage({
                id: `${columnImage}.link`,
              })
              const hasLink = imageLink !== 'null'

              const gif = intl.formatMessage({
                id: `${columnImage}.gif`,
              })
              const isGif = gif !== 'null'
              const iconPath = intl.formatMessage({
                id: `${columnImage}.path`,
              })
              let imagePath = 'imagePath'
              if (!isGif) {
                imagePath = data[`${iconPath}`].childImageSharp.fluid
              } else {
                imagePath = require(`./gifs/${iconPath}.gif`)
              }
              return (
                <StyledImageContainer key={shortid.generate()}>
                  {hasLink ? (
                    <a href={imageLink} target="_blank">
                      {isGif ? (
                        <StyledImageContainer>
                          <img alt={iconPath} src={imagePath} />
                        </StyledImageContainer>
                      ) : (
                        <StyledImageContainer>
                          <Img alt={iconPath} fluid={imagePath} />
                        </StyledImageContainer>
                      )}
                    </a>
                  ) : isGif ? (
                    <StyledImageContainer>
                      <img alt={iconPath} src={imagePath} />
                    </StyledImageContainer>
                  ) : (
                    <StyledImageContainer>
                      <Img alt={iconPath} fluid={imagePath} />
                    </StyledImageContainer>
                  )}
                </StyledImageContainer>
              )
            })}
          </Box>
        )}
        {buttons > 0 && (
          <StyledColumnButtonContainer buttons={buttons}>
            {Array.from(Array(Number(buttons)).keys()).map(i => {
              const button = `button${i}`
              const buttonLabel = intl.formatMessage({
                id: `${section}.buttons.${button}.label`,
              })
              const buttonLink = intl.formatMessage({
                id: `${section}.buttons.${button}.link`,
              })
              return (
                <Button
                  key={shortid.generate()}
                  label={buttonLabel}
                  link={buttonLink}
                  light={shade === 'light' || shade === 'grey' ? true : false}
                />
              )
            })}
          </StyledColumnButtonContainer>
        )}
      </StyledContentContainer>
    </StyledContainer>
  )
}

Centered.propTypes = {
  data: PropTypes.object,
  shade: PropTypes.string,
  index: PropTypes.number,
  intl: intlShape.isRequired,
}

export default injectIntl(Centered)

import {
  StyledColumnContainer,
  StyledColumnsContainer,
  StyledContainer,
  StyledH2,
  StyledH3,
  StyledSpan,
} from './styles'
import { injectIntl, intlShape } from 'react-intl'

import { Box } from 'grommet'
import Button from '../reusables/button'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledImageTextContainer = styled(StyledContainer)`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  grid-template-areas:
    'image'
    'content';
  
    ${media.greaterThan('small')`
    grid-template-columns: ${props =>
      props.shade === 'light' ? '1fr 1fr' : '1fr 1fr'}
    grid-template-rows: 100%;
    grid-column-gap: 20px;
    grid-template-areas:
      "${props =>
        props.shade === 'light' ? 'content image' : 'image content'}";
     
  `}
  ${media.greaterThan('large')`
    grid-template-columns: ${props =>
      props.shade === 'light' ? '610px 1fr' : '1fr 610px'}
    grid-template-rows: 100%;
    grid-template-areas:
      "${props =>
        props.shade === 'light' ? 'content image' : 'image content'}";
     
  `}
`

const StyledImageContainer = styled(Box)`
  grid-area: image;
  align-items: center;

  img {
    max-width: 300px;

    ${media.greaterThan('small')`
      max-width: 368px;
    `}
  }
`

const StyledContentContainer = styled(Box)`
  grid-area: content;
  align-content: center;
  justify-content: center;
`

const ImageText = ({ index, data, shade, intl }) => {
  const section = `imageText.section${index}`
  const title = intl.formatMessage({
    id: `${section}.title`,
  })
  const hasTitle = title !== 'null'
  const description = intl.formatMessage({
    id: `${section}.description.description`,
  })
  const hasDescription = description !== 'null'
  const isRichText =
    intl.formatMessage({
      id: `${section}.description.richText`,
    }) !== 'null'

  const buttons = intl.formatMessage({
    id: `${section}.buttons.number`,
  })
  const gif = intl.formatMessage({
    id: `${section}.image.gif`,
  })
  const isGif = gif !== 'null'
  const iconPath = intl.formatMessage({
    id: `${section}.image.path`,
  })
  let imagePath = 'imagePath'
  if (!isGif) {
    imagePath = data[`${iconPath}`].childImageSharp.fixed
  } else {
    imagePath = require(`./gifs/${iconPath}.gif`)
    // imagePath = require(`./gifs/coinCompressed.gif`)
  }

  return (
    <StyledImageTextContainer componentName="ImageText" shade={shade}>
      {isGif ? (
        <StyledImageContainer>
          <img alt={iconPath} src={imagePath} />
        </StyledImageContainer>
      ) : (
        <StyledImageContainer>
          <Img alt={iconPath} fixed={imagePath} />
        </StyledImageContainer>
      )}
      <StyledContentContainer>
        {hasTitle && <StyledH2>{title}</StyledH2>}
        {hasDescription && !isRichText ? (
          <StyledSpan>{description}</StyledSpan>
        ) : (
          hasDescription &&
          isRichText && (
            <StyledSpan dangerouslySetInnerHTML={{ __html: description }} />
          )
        )}
        {buttons > 0 && (
          <Box
            direction="row-responsive"
            gap="medium"
            pad={{ vertical: 'medium' }}
          >
            {Array.from(Array(Number(buttons)).keys()).map(i => {
              const button = `button${i}`
              const buttonLabel = intl.formatMessage({
                id: `${section}.buttons.${button}.label`,
              })
              const buttonLink = intl.formatMessage({
                id: `${section}.buttons.${button}.link`,
              })
              return (
                <Button
                  key={shortid.generate()}
                  label={buttonLabel}
                  link={buttonLink}
                  light={shade === 'light' || shade === 'grey' ? true : false}
                />
              )
            })}
          </Box>
        )}
      </StyledContentContainer>
    </StyledImageTextContainer>
  )
}

ImageText.propTypes = {
  data: PropTypes.object,
  shade: PropTypes.string,
  index: PropTypes.number,
  intl: intlShape.isRequired,
}

export default injectIntl(ImageText)

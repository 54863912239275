import { Anchor, Box } from 'grommet'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import React, { Component } from 'react'
import { StyledContainer, StyledH3 } from './styles'

import PropTypes from 'prop-types'
import StyledLink from './link'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledH2Box = styled(Box)``
const StyledTitle = styled(StyledH3)`
  color: #0b0c17;
  text-transform: none;
  align-self: center;
  ${media.greaterThan('small')`
    align-self: start;
  `}
`

const StyledMenuContainer = styled(StyledContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  justify-items: center;
`
const StyledBox = styled(Box)`
  display: grid;
`

const IndexMenu = ({ shade, intl }) => {
  const { messages } = intl
  const menus = messages['indexMenu.number']
  return (
    <StyledMenuContainer
      background={
        shade === 'light'
          ? 'primaryWhite'
          : shade === 'grey'
          ? 'primaryGrey'
          : 'primaryDark'
      }
    >
      {Array.from(Array(Number(menus)).keys()).map((i, index) => {
        const menu = `menu${i}`
        const links = intl.formatMessage({
          id: `indexMenu.${menu}.number`,
        })
        const title = intl.formatMessage({
          id: `indexMenu.${menu}.label`,
        })
        return (
          <StyledBox
            key={shortid.generate()}
            direction="column"
            align="center"
            justify="start"
          >
            <StyledH2Box>
              <StyledTitle>{title}</StyledTitle>
            </StyledH2Box>
            {Array.from(Array(Number(links)).keys()).map((j, index) => {
              const linkIndex = `link${j}`
              const link = `indexMenu.${menu}.links.${linkIndex}`
              const label = intl.formatMessage({
                id: `${link}.label`,
              })
              const linkLink = intl.formatMessage({
                id: `${link}.link`,
              })
              return (
                <p key={shortid.generate()}>
                  <StyledLink
                    light={shade === 'light' || shade === 'grey' ? false : true}
                    link={linkLink}
                    label={label}
                  />
                </p>
              )
            })}
          </StyledBox>
        )
      })}
    </StyledMenuContainer>
  )
}

IndexMenu.propTypes = {
  shade: PropTypes.string,
  intl: intlShape.isRequired,
}

export default injectIntl(IndexMenu)

import { Link, withIntl } from '../../i18n'
import { StaticQuery, graphql } from 'gatsby'
import {
  bannerIcon,
  downloadButton,
  feature,
  fluidImage,
  phone,
} from '../../fragments/fragments'

import Banner from '../../components/reusables/banner'
import Centered from '../../components/reusables/centered'
import Features from '../../components/reusables/features'
import { FormattedMessage } from 'react-intl'
import Hero from '../../components/reusables/hero'
import ImageText from '../../components/reusables/imageText'
import ImageTextHero from '../../components/reusables/imageTextHero'
import IndexMenu from '../../components/reusables/indexMenu'
import Layout from '../../components/layout'
import React from 'react'
import Rows from '../../components/reusables/rows'

const CmtWallet = ({ data }) => (
  <Layout>
    <Banner data={data} shade={'light'} />
    <ImageTextHero index={0} data={data} shade={'light'} />
    <ImageText index={0} data={data} shade={'grey'} />
    <Features index={0} data={data} shade={'light'} />
    <Centered index={0} data={data} shade={'grey'} />
  </Layout>
)

export default withIntl(CmtWallet)

export const imagesQuery = graphql`
  query {
    bannerCMT: file(relativePath: { eq: "hero/bannerCMT@3x.png" }) {
      ...bannerIcon
    }
    cmtWalletOne: file(relativePath: { eq: "regular/cmtWalletOne@3x.png" }) {
      ...phone
    }
    cmtWalletTwo: file(relativePath: { eq: "regular/cmtWalletTwo@3x.png" }) {
      ...phone
    }
    featuresKey: file(relativePath: { eq: "features/featuresKey@3x.png" }) {
      ...feature
    }
    featuresPhone: file(relativePath: { eq: "features/featuresPhone@3x.png" }) {
      ...feature
    }
    featuresWorld: file(relativePath: { eq: "features/featuresWorld@3x.png" }) {
      ...feature
    }
    featuresLock: file(relativePath: { eq: "features/featuresLock@3x.png" }) {
      ...feature
    }
    appStore: file(relativePath: { eq: "icons/appStore@3x.png" }) {
      ...downloadButton
    }
    googlePlay: file(relativePath: { eq: "icons/googlePlay@3x.png" }) {
      ...downloadButton
    }
    yingyongbao: file(relativePath: { eq: "icons/yingyongbao@3x.png" }) {
      ...downloadButton
    }
    walletQR: file(relativePath: { eq: "icons/walletQR@3x.png" }) {
      ...downloadButton
    }
    iosWalletQR: file(relativePath: { eq: "icons/qr_cmt_wallet_ios.png" }) {
      ...qrImage
    }
    androidWalletQR: file(relativePath: { eq: "icons/qr_cmt_wallet_android.png" }) {
      ...qrImage
    }
    shortLastSection: file(relativePath: { eq: "hero/shortLastSection@2x.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

import { Box, Grid } from 'grommet'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import React, { Component } from 'react'

import Button from '../reusables/button'
import Img from 'gatsby-image'
import { media } from '../../utilities/theme'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledContainer = styled(Box)`
  display: grid;
  ${media.xlarge`
    height: 100vh;
    grid-template-columns: 60% 40%;
    grid-template-rows: 100%;
    grid-template-areas:
      'content image';
  `}
  ${media.medium`
    height: auto;
    grid-template-rows: 25% auto;
    grid-template-columns: 100%;
    grid-template-areas:
      'image'
      'content';
  `}
`

const StyledImageContainer = styled(Box)`
  grid-area: image;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain !important;
  }
  ${media.xlarge`
    height: 100%;
    width: auto;
  `}
  ${media.medium`
    height: 100%;
    width: 100%;
  `}
`

const StyledButtonGrid = styled(Box)`
  display: grid;
  justify-content: start;
  ${media.xlarge`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  `}
  ${media.medium`
    grid-template-rows: auto;
    grid-template-columns: 100%;
  `}
`

const StyledContentContainer = styled(Box)`
  grid-area: content;
  align-content: center;
  justify-content: center;
`

const Hero = ({ intl, data, light }) => {
  const title = intl.formatMessage({
    id: `hero.title`,
  })
  const hasTitle = title !== 'null'
  const description = intl.formatMessage({
    id: `hero.description.description`,
  })
  const hasDescription = description !== 'null'
  const isRichText =
    intl.formatMessage({
      id: `hero.description.richText`,
    }) !== 'null'

  const image = intl.formatMessage({
    id: `hero.image`,
  })
  const hasImage = image !== 'null'
  let imagePath = ''
  if (hasImage) {
    imagePath = data[`${image}`].childImageSharp.fluid
  }

  const buttons = intl.formatMessage({ id: `hero.buttons.number` })

  const hasButtons = buttons > 0
  return (
    <StyledContainer
      a11yTitle="hero"
      pad="xlarge"
      background="light-1"
    >
      <StyledContentContainer direction="column">
        {hasTitle && <h1>{title}</h1>}
        {hasDescription && !isRichText ? (
          <div>{description}</div>
        ) : (
          hasDescription &&
          isRichText && (
            <div dangerouslySetInnerHTML={{ __html: description }} />
          )
        )}
        {hasButtons && (
          <StyledButtonGrid>
            {Array.from(Array(Number(buttons)).keys()).map((i, index) => {
              const button = `hero.buttons.button${i}`
              return (
                <Button
                  label={intl.formatMessage({ id: `${button}.label` })}
                  link={intl.formatMessage({ id: `${button}.link` })}
                  light={light}
                  key={shortid.generate()}
                />
              )
            })}
          </StyledButtonGrid>
        )}
      </StyledContentContainer>
      {hasImage && (
        <StyledImageContainer>
          <Img alt={image} fluid={imagePath} />
        </StyledImageContainer>
      )}
    </StyledContainer>
  )
}

export default injectIntl(Hero)

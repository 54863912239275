import React, { Component } from 'react'
import {
  StyledColumnContainer,
  StyledColumnsContainer,
  StyledContainer,
  StyledH2,
  StyledH3,
  StyledSpan,
} from './styles'
import { injectIntl, intlShape } from 'react-intl'

import { Box } from 'grommet'
import Button from './button'
import Img from 'gatsby-image'
import Lightbox from 'lightbox-react'
import PropTypes from 'prop-types'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'
import walletQR from '../../assets/images/walletQR.png'

const StyledImageTextContainer = styled(StyledContainer)`
  display: grid;
  justify-items: between;
  align-items: center;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  grid-template-areas:
    'image'
    'content';

  ${media.greaterThan('small')`
  justify-items: between;
    grid-template-rows: 100%;
    grid-column-gap: 20px;
    grid-template-areas:
      "${props =>
        props.shade === 'light' ? 'content image' : 'image content'}";
  `}
  ${media.greaterThan('large')`
    grid-template-columns: ${props =>
      props.shade === 'light' ? '610px 1fr' : '1fr 610px'}
    grid-template-rows: 100%;
    grid-template-areas:
      "${props =>
        props.shade === 'light' ? 'content image' : 'image content'}";
     
  `}
`

const StyledImageContainer = styled(Box)`
  grid-area: image;
  align-items: center;
  height: 50%;

  ${media.greaterThan('small')`
    height: 100%;
  `}

  img {
    max-width: 100%;

    ${media.greaterThan('small')`
      height: 351px;
    `}
  }
`

const StyledContentContainer = styled(Box)`
  grid-area: content;
  align-content: center;
  justify-content: center;
`

const StyledAnchor = styled.a`
  &.withQr {
    position: relative;
    font-size: 0.6em;
    text-align: center;
    color: #666;

    .qr {
      display: none;
      position: absolute;
      width: 220px;
      height: 250px;
      background-color: #fff;
      border-radius: 8px;
      border: solid 1px #e5e5e5;
      left: 50%;
      margin-left: -110px;
      padding: 27px;
    }

    @media (hover: hover) {
      &:hover .qr {
        display: block;
      }
    }
  }
`

class ImageTextHero extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }
  render() {
    const { index, data, shade, intl } = this.props
    const { isOpen } = this.state
    const section = `imageTextHero.section${index}`
    const title = intl.formatMessage({
      id: `${section}.title`,
    })
    const hasTitle = title !== 'null'
    const description = intl.formatMessage({
      id: `${section}.description.description`,
    })
    const hasDescription = description !== 'null'
    const isRichText =
      intl.formatMessage({
        id: `${section}.description.richText`,
      }) !== 'null'

    const imageButtons = intl.formatMessage({
      id: `${section}.imageButtons.number`,
    })
    const buttons = intl.formatMessage({
      id: `${section}.buttons.number`,
    })
    const gif = intl.formatMessage({
      id: `${section}.image.gif`,
    })
    const isGif = gif !== 'null'
    const iconPath = intl.formatMessage({
      id: `${section}.image.path`,
    })
    let imagePath = 'imagePath'
    if (!isGif) {
      imagePath = data[`${iconPath}`].childImageSharp.fixed
    } else {
      imagePath = require(`./gifs/${iconPath}.gif`)
    }

    return (
      <StyledImageTextContainer componentName="ImageTextHero" shade={shade}>
        {isGif ? (
          <StyledImageContainer>
            <img alt={iconPath} src={imagePath} />
          </StyledImageContainer>
        ) : (
          <StyledImageContainer>
            <Img alt={iconPath} imgStyle={{ objectFit: 'contain' }} fixed={imagePath} />
          </StyledImageContainer>
        )}
        <StyledContentContainer>
          {hasTitle && <StyledH2>{title}</StyledH2>}
          {hasDescription && !isRichText ? (
            <StyledSpan>{description}</StyledSpan>
          ) : (
            hasDescription &&
            isRichText && (
              <StyledSpan dangerouslySetInnerHTML={{ __html: description }} />
            )
          )}
          {imageButtons > 0 && (
            <Box
              direction="row-responsive"
              gap="medium"
              pad={{ vertical: 'medium' }}
            >
              {Array.from(Array(Number(imageButtons)).keys()).map(i => {
                const button = `button${i}`
                const icon = intl.formatMessage({
                  id: `${section}.imageButtons.${button}.icon`,
                })
                const iconPath = data[`${icon}`].childImageSharp.fixed
                const qr = intl.formatMessage({
                  id: `${section}.imageButtons.${button}.qr`,
                })
                const qrText = intl.formatMessage({
                  id: `${section}.imageButtons.${button}.qrText`,
                })
                let qrPath
                if (data[`${qr}`]) {
                  qrPath = data[`${qr}`].childImageSharp.fixed
                }
                const buttonLink = intl.formatMessage({
                  id: `${section}.imageButtons.${button}.link`,
                })
                return icon === 'walletQR' ? (
                  <StyledAnchor
                    key={shortid.generate()}
                    onClick={() => this.setState({ isOpen: true })}
                  >
                    <Img alt={iconPath} fixed={iconPath} />
                  </StyledAnchor>
                ) : (
                  <StyledAnchor href={buttonLink} target="_blank" className={qrPath?'withQr':''}>
                    <Img alt={iconPath} fixed={iconPath} />
                    {
                      qrPath ?
                      <div className="qr">
                        <Img alt={qrPath} fixed={qrPath} />
                        {qrText}
                      </div>
                      :
                      ''
                    }
                  </StyledAnchor>
                )
              })}
            </Box>
          )}
          {buttons > 0 && (
            <Box
              direction="row-responsive"
              gap="medium"
              pad={{ vertical: 'medium' }}
            >
              {Array.from(Array(Number(buttons)).keys()).map(i => {
                const button = `button${i}`
                const buttonLabel = intl.formatMessage({
                  id: `${section}.buttons.${button}.label`,
                })
                const buttonLink = intl.formatMessage({
                  id: `${section}.buttons.${button}.link`,
                })
                return (
                  <Button
                    key={shortid.generate()}
                    label={buttonLabel}
                    link={buttonLink}
                    light={shade === 'light' || shade === 'grey' ? true : false}
                  />
                )
              })}
            </Box>
          )}
          {isOpen && (
            <Lightbox
              mainSrc={walletQR}
              onCloseRequest={() => this.setState({ isOpen: false })}
            />
          )}
        </StyledContentContainer>
      </StyledImageTextContainer>
    )
  }
}

ImageTextHero.propTypes = {
  data: PropTypes.object,
  shade: PropTypes.string,
  index: PropTypes.number,
  intl: intlShape.isRequired,
}

export default injectIntl(ImageTextHero)
